import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchItems(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/partner/list', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchItem(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/partner/get', {
                        params: queryParams
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        approveItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/partner/approve?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        declineItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/partner/decline?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        savePaidStatus(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/partner/change-paid-status`, dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/partner/delete?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        serviceAgreement(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/partner/pdf-agreement', {
                        params: queryParams
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },        
    },
}
