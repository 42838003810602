<template>
  <b-card>
    <b-tabs pills content-class="border-top">
      <b-tab active>
        <template #title>
          <feather-icon icon="BookIcon"/>
          <span>General</span>
        </template>

        <b-card no-body class="border mt-1 mb-0 p-2">

          <table class="mt-2 mt-xl-0 w-100">
            <tr class="border-bottom">
              <th class="pb-50"><span class="font-weight-bold">First Name</span></th>
              <td class="pb-50">{{ dataInfo.partner.first_name }}</td>
            </tr>
            <tr class="border-bottom">
              <th class="py-50"><span class="font-weight-bold">Last Name</span></th>
              <td class="py-50">{{ dataInfo.partner.last_name }}</td>
            </tr>
            <tr class="border-bottom">
              <th class="py-50"><span class="font-weight-bold">Email</span></th>
              <td class="py-50">{{ dataInfo.partner.email }}</td>
            </tr>
            <tr class="border-top">
              <th class="py-50"><span class="font-weight-bold">Phone Number</span></th>
              <td class="py-50">{{ dataInfo.partner.phone }}</td>
            </tr>
            <tr class="border-top">
              <th class="py-50"><span class="font-weight-bold">Address</span></th>
              <td class="py-50">{{ dataInfo.partner.address }}</td>
            </tr>
            <tr class="border-top">
              <th class="py-50"><span class="font-weight-bold">Referral Code</span></th>
              <td class="py-50">{{ dataInfo.partner.referral_code }}</td>
            </tr>
          </table>

        </b-card>

      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="GiftIcon"/>
          <span>Coupons</span>
        </template>
        <b-card no-body class="border mt-1 mb-0">

          <b-table
              ref="refListTable"
              primary-key="id"
              empty-text="No record found"
              :class="`position-relative`"
              :items="dataInfo.coupons"
              :fields="couponColumns"
              responsive striped bordered hover show-empty>

            <template #cell(id)="data">
              <b-link class="font-weight-bold">#{{ data.value }}</b-link>
            </template>

            <template #cell(value_type)="data">
              <span v-if="data.value === 1">Percentage</span>
              <span v-if="data.value === 2">Fixed</span>
            </template>

            <template #cell(percentage_value)="data">
              <span>{{ data.value }}%</span>
            </template>

            <template #cell(status)="data">
              <active-column-table :data="data.value"></active-column-table>
            </template>

          </b-table>

        </b-card>
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="UsersIcon"/>
          <span>Students</span>
        </template>
        <b-card no-body class="border mt-1 mb-0">

          <b-row class="p-1 d-flex justify-content-start align-items-center">

            <b-col cols="3" md="3" lg="3">
              <b-form-group label="Start Date">
                <flat-pickr v-model="startDate" class="form-control"/>
              </b-form-group>
            </b-col>

            <b-col cols="3" md="3" lg="3">
              <b-form-group label="End Date">
                <flat-pickr v-model="endDate" class="form-control"/>
              </b-form-group>
            </b-col>

            <b-col cols="2" md="2" lg="2">
              <b-button type="button" variant="primary" @click="getData">
                Search
              </b-button>
            </b-col>

            <b-col cols="4" md="4" lg="4" class="d-flex justify-content-start align-items-center flex-column pt-1 pl-5">
              <strong>Total Progress Payment: </strong>
              <h3>${{ totalProgressPayment }}</h3>
            </b-col>


          </b-row>

          <b-table
              ref="refListTable"
              primary-key="id"
              empty-text="No record found"
              :class="`position-relative`"
              :items="dataInfo.students"
              :fields="studentColumns"
              responsive striped bordered hover show-empty>

            <template #cell(id)="data">
              <b-link class="font-weight-bold">#{{ data.value }}</b-link>
            </template>

            <template #cell(student_created_date)="data">
              <date-time-column-table :data="data.value"></date-time-column-table>
            </template>

            <template #cell(payment_created_date)="data">
              <date-time-column-table :data="data.value"></date-time-column-table>
            </template>

            <template #cell(payment_amount)="data">
              <span v-if="data.value > 0">${{ data.value }}</span>
            </template>

            <template #cell(progress_payment)="data">
              <span>${{ data.value }}</span>
            </template>

            <template #cell(paid_status)="data">
              <b-badge pill :variant="`light-success`" class="text-capitalize" v-if="data.item.paid_status">
                Successfully
              </b-badge>
              <b-badge pill :variant="`light-secondary`" class="text-capitalize" v-if="!data.item.paid_status">
                Waiting
              </b-badge>
            </template>

            <template #cell(paid_date)="data">
              <date-time-column-table :data="data.value"></date-time-column-table>
            </template>

            <template #cell(partner_paid)="data">
              <b-form-checkbox :checked="data.item.partner_paid" disabled v-if="data.item.paid_status"/>
              <b-form-checkbox :checked="data.item.partner_paid" @change="changePaidValue(data.item)" v-if="!data.item.paid_status"/>
            </template>

          </b-table>

          <b-row class="p-1">
            <b-col cols="12" md="12" lg="12" class="d-flex justify-content-end align-items-end">
              <b-button type="button" variant="outline-primary" @click="savePaidStatus">
                Save Paid Status
              </b-button>
            </b-col>
          </b-row>

        </b-card>
      </b-tab>
    </b-tabs>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BAvatar, BTabs, BTab, BButtonToolbar, BTooltip, BTable, BPagination, BButtonGroup, BLink, BFormCheckbox, BBadge,
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email} from '@validations'
import {onUnmounted, ref, computed} from '@vue/composition-api'
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/partner/store"
import router from '@/router'
import {useToast} from 'vue-toastification/composition'
import {avatarText, getApiFile, statusOptions, genderOptions} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue"
import ActionButtons from "@/components/Form/ActionButtons.vue"
import {toastMessage} from "@core/utils/utils"
import {ActiveColumnTable, BooleanColumnTable, DateColumnTable, FooterTable} from "@/components/Table";
import DateTimeColumnTable from "@/components/Table/DateTimeColumnTable.vue";
import flatPickr from 'vue-flatpickr-component'
import Vue from "vue";

export default {
  components: {
    BBadge,
    BFormCheckbox,
    BLink,
    BButtonGroup,
    BPagination,
    BTable,
    BTooltip,
    BButtonToolbar,
    BTab,
    BTabs,
    BAvatar,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,
    flatPickr,

    FooterTable,
    ActiveColumnTable,
    BooleanColumnTable,
    DateTimeColumnTable,
    DateColumnTable,

    vSelect,
    ActionButtons,
    Overlay,

    ValidationProvider,
    ValidationObserver,
  },
  props: {},
  data() {
    return {
      required,
      email,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
    }

    const startDate = ref(null)
    const endDate = ref(null)

    startDate.value = new Date()
    endDate.value = new Date()
    startDate.value.setDate(startDate.value.getDate() - 30)

    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
      }
    })

    const toast = useToast()
    const busy = ref(false)

    const dataInfo = ref({
      partner: {},
      coupons: [],
      students: [],
    })

    const couponColumns = [
      {key: 'id', label: '#', sortable: false, thStyle: {width: "1%"}},
      {key: 'code', label: 'CODE', sortable: false},
      {key: 'value_type', label: 'VALUE TYPE', sortable: false},
      {key: 'value', label: 'VALUE', sortable: false},
      {key: 'percentage_value', label: 'PERCENTAGE VALUE', sortable: false},
      {key: 'status', label: 'STATUS', sortable: false, thStyle: {width: "1%"}},
    ]

    const studentColumns = [
      {key: 'id', label: '#', sortable: false, thStyle: {width: "1%"}},
      {key: 'first_name', label: 'FIRST NAME', sortable: false},
      {key: 'last_name', label: 'LAST NAME', sortable: false},
      {key: 'student_created_date', label: 'CREATED DATE', sortable: false},
      {key: 'payment_created_date', label: 'PAYMENT DATE', sortable: false},
      {key: 'payment_amount', label: 'PAYMENT AMOUNT', sortable: false},
      {key: 'payment_coupon_code', label: 'COUPON CODE', sortable: false},
      {key: 'progress_payment', label: 'PROGRESS PAYMENT', sortable: false},
      {key: 'paid_status', label: 'PAID STATUS', sortable: false},
      {key: 'paid_date', label: 'PAID DATE', sortable: false},
      {key: 'partner_paid', label: 'PAID', sortable: false},
    ]

    const getData = () => {
      busy.value = true
      store.dispatch('store/fetchItem', {id: router.currentRoute.params.id, start_date: startDate.value, end_date: endDate.value}).then(response => {
        dataInfo.value = response.data.data
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.error_message)
        if (error.response.status === 403) {
          router.push({name: 'partner-list'})
        }
      }).finally(message => {
        busy.value = false
      })
    }

    if (router.currentRoute.params.id > 0) {
      getData()
    }

    const totalProgressPayment = computed(() => {
      if (dataInfo.value.students.length > 0) {
        return dataInfo.value.students.reduce((accum, item) => {
          return (parseFloat(accum) + parseFloat(item.progress_payment)).toFixed(2)
        }, 0.0)
      } else {
        return 0
      }
    })

    const changePaidValue = (data) => {
      dataInfo.value.students.forEach(function callback(value, index) {
        if (value.payment_id === data.payment_id) {
          value.partner_paid = !data.partner_paid
        }
      })
    }

    const savePaidStatus = () => {
      Vue.swal({
        title: 'Change Paid Status?',
        text: 'Record will be updated!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Paid',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('store/savePaidStatus', {id: router.currentRoute.params.id, students: dataInfo.value.students}).then(response => {
            getData()
          }).catch(error => {
            Vue.swal({
              icon: 'error',
              title: 'Error!',
              text: error.response.data.message,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
        }
      })
    }

    return {
      busy,
      dataInfo,
      couponColumns,
      studentColumns,
      startDate,
      endDate,
      totalProgressPayment,

      genderOptions,
      statusOptions,

      getApiFile,
      avatarText,
      getData,
      changePaidValue,
      savePaidStatus,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
